<template>
  <div id="Telegram">
    <TheHeader></TheHeader>
    <SiteNav></SiteNav>
    <div class="px-3 py-3 bg-lines bg-cover bg-fixed bg-center">
      <div class="md:container md:mx-auto">
        <div class="notification mx-auto p-3 max-w-md text-center bg-gray-800 rounded-md shadow-md" v-if="step == 0">
          <font-awesome-icon :icon="['fab', 'telegram']" class="icon icon-big" />
          <p class="headline my-3">Verbinde Dich mit unserem Telegram Bot</p>
          <hr class="line my-3" />
          <p class="text my-3">
            Mit dem Nachrichten-Dienst
            <a href="https://telegram.org/" target="_newTab" class="link"
              >Telegram</a
            >
            kannst du kostenfrei und komfortable Echtzeit-Benachrichtigungen von unserem StadtbahnfahrerClub
            Bot erhalten, ohne unsere Website aufzurufen. Unser Bot versorgt Dich täglich mit Deinem Dienstplan und mit wichtigen Status-Hinweisen zu Deinem StadtbahnfahrerClub Konto.
          </p>
          <button class="button-filled text-center btn-fullsize w-full" @click="nextStep()" v-if="!isLoading">
            Jetzt einrichten
            &nbsp;<font-awesome-icon icon="chevron-right" class="icon" />
          </button>
        </div>

        <div class="registration_complete mx-auto p-3 max-w-md text-center bg-gray-800 rounded-md shadow-md" v-if="step == 3">
          <font-awesome-icon :icon="['fab', 'telegram']" class="icon icon-big" />
          <p class="headline my-3">Telegram Bot</p>
          <hr class="line my-3" />
          <p class="text my-3">
            <font-awesome-icon icon="check" class="color-success" /> Erfolgreich
            verknüpft!<br /><br />
            <a href="../einstellungen/telegram" class="link"
              >Einstellungen verwalten</a
            >
          </p>
        </div>

        <div v-if="step > 0 && step < 3">

          <div class="notification">
            <font-awesome-icon :icon="['fab', 'telegram']" class="icon icon-big" />
            <p class="headline">Telegram Bot verknüpfen</p>
          </div>


          <div class="content-card card-fullscreen flex-1 mt-4 bg-gray-800 rounded-md shadow-md w-full flex flex-col justify-between">
            <div class="card-header flex flex-col sm:flex-row flex-wrap gap-2 justify-around items-center px-4 py-6 bg-gray-900 rounded-t-md">
              <!-- <div class="card-header-flex  space"> -->
                <div class="card-header-flex space-x-2 flex flex-row justify-between items-center px-4">
                  <div class="circle" v-bind:class="{ active: step == 1 }">1</div>
                  <div class="title" v-bind:class="{ active: step == 1 }">
                    Bot anschreiben
                  </div>
                </div>
                <div class="card-header-flex space-x-2 flex flex-row justify-between items-center px-4">
                  <div class="circle" v-bind:class="{ active: step == 2 }">2</div>
                  <div class="title" v-bind:class="{ active: step == 2 }">
                    Authentifizierung
                  </div>
                </div>
            </div>
            <div class="card-content p-6">
              <div class="list" v-if="step == 1">
                <p class="text-center"><b>Bot anschreiben</b></p>

                <div class="my-6 grid grid-cols-1 sm:grid-cols-2 gap-2 text-center">
                  <div class="col-6">
                    <p><b>Schnellstart</b></p>
                    <p>QR Code per Smartphone scannen:</p>
                    <img
                      src="@/assets/qrcode_telegram.png"
                      height="120"
                      class="mx-auto"
                    />
                  </div>

                  <div class="col-6">
                    <p><b>... oder manuell</b></p>
                    <ul>
                      <li>
                        Öffne
                        <a
                          href="https://web.telegram.org/#/im?p=@StadtbahnfahrerClubBot"
                          target="_newTab"
                          class="link"
                          >Telegram</a
                        >
                        per App, Web oder Desktop Anwendung
                      </li>
                      <li>Suche nach <i>StadtbahnfahrerClub Bot</i></li>
                      <li>
                        Starte eine neue Konversation indem du
                        <kbd>/start</kbd> in den Chat eingibst
                      </li>
                    </ul>
                  </div>
                </div>

                <button
                  @click="nextStep()"
                  class="button-filled text-center btn-fullsize w-full"
                >Zum nächsten Schritt
                  &nbsp;<font-awesome-icon icon="chevron-right" class="icon" />
                </button>
              </div>

              <div class="list" v-if="step == 2">
                <p class="text-center"><b>Authentifizierung</b></p>
                <p class="text-center">
                  Für die Einrichtung und Authentifizierung benötigst du deinen
                  geheimen Code.<br />Die Eingabe des Codes ist nur einmal
                  nötig.
                </p>

                <div v-if="code">

                  <!-- <div class="mb-6">
                    <p class="text-center input-helper-text" v-if="!showMoreInfos">
                      <font-awesome-icon icon="eye-slash" class="icon" /> Teilen den Code mit niemanden.<br />Mit ihm können
                    auf sensible Daten zugegriffen werden.
                    </p>
                  </div> -->

                  <div class="notification flex gap-6 p-3 my-3 text-black bg-gray-200 rounded-lg justify-center">
                    <div class="self-center pl-3">
                      <font-awesome-icon icon="eye-slash" class="icon text-4xl text-blue-900" />
                    </div>
                    <div class="">
                      <!-- <p class="headline text-lg leading-6 font-medium">E-Mail Adresse bestätigen</p> -->
                      <p class="text">
                        Teilen den Code mit niemanden.<br />Mit ihm können
                    auf sensible Daten zugegriffen werden.
                      </p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 text-center">
                      <label>Dein geheimer Code</label>
                      <input
                        type="text"
                        class="text-center w-full"
                        :value="code"
                        disabled
                      />
                      <small class="input-helper-text"
                        >Der Code verliert beim Verlassen der Seite seine
                        Gültigkeit!</small
                      >
                    </div>
                  </div>

                  <p class="my-6 text-center space">
                    Sende in Telegram folgende Nachricht an den <a href="https://t.me/StadtbahnfahrerClubBot" target="_newTab" class="link">StadtbahnfahrerClub Bot</a><br /><kbd>/anmelden {{ code }}</kbd
                  ></p>

                  <!-- <div class="my-6 grid grid-cols-1 sm:grid-cols-2 gap-2 text-center">
                    <p class="text-center space">
                      Sende in Telegram folgende Nachricht an den <a href="https://t.me/StadtbahnfahrerClubBot" target="_newTab" class="link">StadtbahnfahrerClub Bot</a><br /><kbd
                        >/anmelden {{ code }}</kbd
                      ></p>
                      <p class="text-center space">Oder klicke direkt auf den Link:<br>
                      <a
                        :href="
                          'https://t.me/StadtbahnfahrerClubBot?anmelden=' + code
                        "
                        target="_newTab"
                        class="link"
                        >https://t.me/StadtbahnfahrerClubBot?anmelden={{
                          code
                        }}</a
                      >
                    </p>
                  </div> -->

                  <div class="list space">
                    <button
                      :disabled="step > 1"
                      v-if="step < 5"
                      @click="nextStep()"
                      class="button-filled text-center w-full"
                    >
                      <span v-if="step > 1"
                        >Wir warten auf Deine Telegram Nachricht...</span
                      ><span v-if="step < 2"
                        >Zum nächsten Schritt
                        <font-awesome-icon
                          icon="chevron-right"
                          class="icon" /></span
                      ><span v-if="step > 3"
                        >Fast geschafft
                        <font-awesome-icon icon="chevron-right" class="icon"
                      /></span>
                    </button>
                  </div>
                </div>
                <div v-else>
                  <p class="text-center space">
                    Dein Code wird generiert.<br />Einen Moment bitte...
                  </p>
                </div>
              </div>




            </div>
          </div>




          

          
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import SiteNav from "@/components/TheNavigation";
import TheHeader from "@/components/TheHeader";

import { mapGetters } from "vuex";

export default {
  name: "Telegram",
  components: {
    SiteNav,
    TheHeader,
  },
  data() {
    return {
      code: false,
      dbId: null,
      step: 0,
      intervalId: null,
      isLoading: true,
    };
  },
  methods: {
    nextStep() {
      this.step = this.step + 1;

      if (this.step == 2) {
        this.$store.dispatch("generateTelegramCode").then((a) => {
          // console.log(a);
          if (a.status == true) {
            this.code = a.response.code;
            this.dbId = a.response.id;
            this.fetchTelegramStatus();
          } else {
            console.error("Error with generateTelegramCode");
            alert(
              "Es ist ein Problem bei der Einrichtung von Telegram aufgetreten. Versuche es bitte erneut."
            );
          }
        });
      }
    },

    fetchTelegramStatus() {
      this.intervalId = setInterval(() => {
        this.$store.dispatch("fetchTelegramStatus", this.dbId).then((a) => {
          if (a.status == true && a.response.complete) {
            this.step = this.step + 1;
            clearInterval(this.intervalId);
          }
        });
      }, 8000);
    },
  },
  mounted() {
    this.$store.dispatch("fetchUserProfile");

    this.$store.dispatch("fetchTelegramStatusForUser").then((a) => {
      if (a.status) {
        if (a.response.data.length == 0) {
          this.isLoading = false;
        } else {
          console.log("Telgram is already configurated");
          this.$router.push('einstellungen/telegram')
        }
      }
    });
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>

<style scoped>
.icon-big {
    height: 60px;
    width: 60px;
    @apply mx-auto m-6
}
</style>